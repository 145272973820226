<template>
  <div class="overflow-hidden" style="height: auto">
    <div class="flex gap-x-4 items-center md:justify-end justify-center text-right mx-4 mb-3">
      <span
        >Showing {{ startItem }} -
        {{ endItem }}
        of {{ data.length }}</span
      >
      <select
        name=""
        id=""
        class="px-1 pr-8 md:py-2 py-3 rounded-xl focus:outline-none focus:border-outlineGray border border-outlineGray text-center text-sm font-medium uppercase"
        v-model="pageSize"
        @change="updateSize($event.target.value)"
      >
        <option
          v-for="s in ['15', '20', '30', '40', '50', '60', '70', '80', '90', '100']"
          :key="s"
          :value="s"
          :selected="s === '15' ? true : false"
        >
          {{ s }}
        </option>
      </select>
    </div>
    <div class="mx-4 h-full md:block hidden" :class="editInProgress ? 'z-20 ' : ''">
      <DataTable
        :value="visibleData"
        v-model:selection="selectedEmployees"
        dataKey="employeeId"
        :resizableColumns="true"
        columnResizeMode="expand"
        showGridlines
        :paginator="false"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        :rowsPerPageOptions="[10, 20, 50]"
        :scrollable="true"
        scrollHeight="600px"
        :loading="!data.length"
        scrollDirection="both"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        class="editable-cells-table p-datatable-sm"
        responsiveLayout="scroll"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        @rowClick="onRowClick"
        :selectAll="checkAll"
        @select-all-change="selectAllEmps"
      >
        <Column selectionMode="multiple" :headerStyle="headStyle" :bodyStyle="bodyCheckStyle" id="custom"></Column>

        <Column
          class="cursor-pointer capitalize text-dark-800"
          v-for="col of header"
          :field="col.field"
          :header="label(col.header)"
          :key="col.field"
          :frozen="freeze(col)"
          style="width: 180px; border: 1px solid #e7e8e7; font-size: 14px; color: #231f1f; z-index: 2"
          :style="[highlightField(col.field), hiddenFields.includes(col.field) ? 'display: none' : '']"
          :class="highlight.includes(col.field) ? 'shadow' : undefined"
          :headerStyle="[
            'margin-bottom: 10px',
            highlightHeader(col.header),
            col.header === header[header.length - 1].field ? lastHeaderStyle : style,
            col.header === active ? activeHeader : style,
          ]"
        >
          <template v-if="useSelect.includes(col.field)" #editor="{ data, field }">
            <!-- <Dropdown
              v-model="data[field]"
              :options="returnOptions(field)"
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              @change="updateMultipleCells($event, data, field)"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option.label }}</span>
              </template>
            </Dropdown> -->
            <Dropdown
              v-model="data[field]"
              :options="returnOptions(field)"
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option.label }}</span>
              </template>
            </Dropdown>
          </template>

          <template
            v-else-if="!useSelect.includes(col.field) && readonly === false && !nonInputs.includes(col.field)"
            #editor="{ data, field }"
          >
            <!-- <InputText
              @focus="editInProgress = true"
              v-model="data[field]"
              :ref="field"
              @keyup="updateMultipleCells($event, data, field)"
              autofocus
            /> -->
            <InputText @focus="editInProgress = true" v-model="data[field]" :ref="field" autofocus />
          </template>

          <template v-if="col.field === 'firstName'" #body="slotProps">
            <div class="flex gap-2 items-center">
              <div class="flex items-center gap-2">
                <div v-if="slotProps.data.picture" class="border w-9 h-9 rounded-full overflow-hidden mr-2">
                  <img class="w-full object-cover" :src="slotProps.data.picture" />
                </div>
                <div v-else class="p-2 h-9 w-9 bg-primary text-sm text-white font-medium rounded-full mr-2">
                  {{ getAvatar(slotProps.data).initialLetters }}
                </div>
              </div>
              <div
                class="capitalize text-sm"
                :class="[error(slotProps.data, col.field), bankError(slotProps.data, col.field)]"
              >
                {{ slotProps.data[col.field] }}
              </div>
            </div>
          </template>
          <template v-else-if="amountValues.includes(col.field)" #body="slotProps">
            <!-- <div class="flex items-center gap-2"></div> -->
            <div class="flex flex-col gap-2 w-full">
              <Tag
                v-if="col.field === 'grossPay' && slotProps.data.prorate && slotProps.data.prorate.proratedSalary"
                style="width: 80px"
                severity="info"
                class="self-end -mr-2 -mt-2 text-xs"
              >
                <span class="text-xs">PRORATED</span></Tag
              >
              <div :class="[error(slotProps.data, col.field), bankError(slotProps.data, col.field)]" class="pb-0">
                {{ formatAmount(slotProps.data[col.field]) }}
              </div>
            </div>
          </template>

          <!--For Benefits Array -->
          <template v-else-if="col.field === 'deductions'" #body="slotProps">
            <div class="flex items-center gap-2"></div>
            <div :class="[error(slotProps.data, col.field), bankError(slotProps.data, col.field)]">
              {{ getDeductionLabel(slotProps.data[col.field]).join(",") }}
            </div>
          </template>

          <template v-else-if="namesForAllowances.includes(col.field)" #body="slotProps">
            <div class="flex items-center gap-2"></div>
            <div :class="[error(slotProps.data, col.field), bankError(slotProps.data, col.field)]">
              {{
                slotProps.data["usePercentage"] === true
                  ? formatAmount(calculatePercentage(slotProps.data["grossPay"], slotProps.data[col.field]))
                  : formatAmount(slotProps.data[col.field])
              }}
            </div>
          </template>

          <template v-else #body="slotProps">
            <div>{{ slotProps.data[col.field] || "N/A" }}</div>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="md:hidden block">
      <MobilePayrollTable
        :data="visibleData"
        :frozenFields="['firstName', 'lastName']"
        :highlight="['firstName', 'lastName']"
        :header="header"
        :allowanceNames="allowanceNames"
        :hideAllowances="$route.name === 'EditSalary' || $route.name === 'ViewSalary' ? true : false"
        :routable="$route.name === 'EditSalary' || $route.name === 'ViewSalary' ? true : false"
        @selectedData="emitSelectedFromMobile($event)"
        @mobileData="updateForMobile($event)"
      />
    </div>

    <easiPagin
      v-if="payrollData.length"
      :data="payrollData"
      @page:update="updatePage"
      :currentPage="currentPage"
      @size="updateSize"
      :pageSize="pageSize"
      :visibleData="visibleData"
      :showSizes="true"
      class="w-full mt-8"
    />

    <div
      @click="editInProgress = false"
      :class="!editInProgress ? 'pointer-events-none' : ''"
      class="bg-transparent z-50 fixed inset-0"
    ></div>

    <!-- <easiModal
      :isBorder="false"
      v-if="showModalForMultipleEdit"
      @close="showModalForMultipleEdit = false"
    >
    
      <EditGroup
        @closeEdit="treat"
        :multipleEdit="true"
        type="edit"
        :edit="true"
        @uploadToServer="sendToPage($event)"
        :multipleData="holdData"
      />
    </easiModal> -->
  </div>
</template>

<script lang="js">
import { FilterMatchMode } from "primevue/api";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";

import { useDataStore } from "@/stores/data.js";
import EditGroup from "@/components/Payroll/Edit/Benefits/Benefits.vue";

const toast = useToast();
import { ref, computed, watch } from "vue";

export default {
  setup(props) {
    const store = useDataStore();
    const { query, mutate } = store;
    const { listEmployees, getAllEmployees } = storeToRefs(store);
    const showModalForMultipleEdit = ref(false);

    const { formatAmount, calculatePercentage } = helperFunctions;
    const editInProgress = ref(false);
    const allowance = ref([]);
    const payrollData = ref([]);
    const allAllowanceData = computed(() => store.listAllowance);
    const allowanceNames = ref([]);
    const editedAllowanceDetails = ref([]);
    const hiddenFields = ref([
      "allowanceDetails",
      "usePercentage",
      "employeeId",
      "staffLevelId",
      "picture",
      "prorate"
    ]);

    //Pagination
    const currentPage = ref(0);
    const pageSize = ref(15);
    let visibleData = ref([]);

    const updatePage = (pageNumber) => {
      currentPage.value = pageNumber;
      updateVisibleData();
    };

    const startItem = computed(() => {
  return currentPage.value * pageSize.value + 1;
});

const endItem = computed(() => {
  const lastItem = (currentPage.value + 1) * pageSize.value;
  return Math.min(lastItem, props.data.length);
});
    const updateVisibleData = () => {
      let datas = payrollData.value;
      visibleData.value = datas.slice(
        currentPage.value * pageSize.value,
        currentPage.value * pageSize.value + pageSize.value
      );

      // if we have 0 visible todos, go back a page
      if (visibleData.value.length == 0 && currentPage.value > 0) {
        updatePage(currentPage.value - 1);
      }
      console.log(visibleData.value, 'vd-V')
    };
    const updateSize = (sz) => {
  pageSize.value = Number(sz);
  updateVisibleData();
};

    function processPropData() {
      const data = props.data
        .map((pd) => pd.allowanceDetails)
        .map((ad) => {
          return ad&& ad.map((det) => {
            return {
              allowanceId: det.allowanceId,
              // _id: det._id,
              _id: null,
              amount: det.amount,
              percentage: det.percentage,
            };
          });
        });

      return data;
    }
    const individualAllowanceDetails = processPropData();

    function allAllowance() {
      let names = []
      if(allAllowanceData.value&&allAllowanceData.value.data){
        names = allAllowanceData.value.data.map((n) => {
        return n.name;
      });
      }

      return names;
    }
    allowanceNames.value = allAllowance();

    function extractAllowance(arr) {
      const allowance = arr.map((obj) => {
        const details = obj.allowanceDetails&& obj.allowanceDetails.map((ad) => {
          return {
            amount: ad.amount,
            percentage: ad.percentage,
            // usePercentage: obj.staffLevel.usePercentageForAllowance,
            name: getAllowanceNameById(ad.allowanceId),
            allowanceId: ad.allowanceId,
            // _id: ad._id,
            _id: null
          };
        });

        return details;
      });
      return allowance;
    }
    allowance.value = extractAllowance(props.data);

    function finalVal() {
      const fna = allowance.value.map((item, index) => {
        const info = item&& item.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.name]: cur.percentage || cur.amount,
            [`${cur.name}allowanceId`]: cur.allowanceId,
            [`${cur.name}_id`]: cur._id,
          }),
          {}
        );
        return info;
      });
      return fna;
    }

    const datum = ref([]);
    datum.value = finalVal();

    function cross() {
      const employee = props.data;
      let newTransformed = [];

      if (employee && allAllowanceData.value && allAllowanceData.value && allAllowanceData.value.data) {
        const fresh = employee.map((emp, index) => {
          const fin = datum.value.map((du, i) => {
            if (index === i) {
              const merged = Object.assign(emp, du);
              newTransformed.push(merged);
              return merged;
            }
          });
        });
        for (let all of newTransformed) {
          for (let k of allowanceNames.value) {
            if (!all[k]) {
              all[k] = 0;
              all[`${k}_id`] = "";
              all[`${k}allowanceId`] = "";
            }
          }
        }

        for (let all of newTransformed) {
          for (let k of allAllowanceData.value.data) {
            if (all[`${k.name}allowanceId`]&&!all[`${k.name}allowanceId`].length) {
              all[`${k.name}allowanceId`] = k._id;
            }
          }
        }
      }
      return newTransformed;
    }
    payrollData.value = cross();
    updateVisibleData();
    function getAllowanceNameById(id) {
      let allName =''
      if(allAllowanceData.value&&allAllowanceData.value.data&&allAllowanceData.value.data.length){
      for (let data of allAllowanceData.value.data) {

        if (data._id === id) {
          allName = data.name;


        }
      }}
      return allName;
    }

    // const header = computed(() => {
    //   let headArr = [];
    //   const head = Object.keys(payrollData.value[0]);
    //   for (let i of head) {
    //     headArr.push({
    //       field: i,
    //       header: i,
    //     });
    //   }
    //   console.log(headArr, "AEE");
    //   return headArr;
    // });

    function triggerUpdate(payrollArr) {
      const data = payrollArr.map((d) => {
        return allowanceNames.value.map((n) => {
          return {
            allowanceId: d[`${n}allowanceId`],
            _id: null,
            amount: !d.usePercentage ? Number(d[n]) : null,
            percentage: d.usePercentage ? Number(d[n]) : null,
          };
        });
      });
      return data;
    }

    function updateAllowanceArray(data) {
      // const update = allowanceNames.value.map((name, ind) => {
      //   return individualAllowanceDetails.map((dat, i) => {
      //     return editedAllowanceDetails.value.map((ea, index) => {
      //       if (
      //         ea[ind] !== undefined &&
      //         dat[ind] !== undefined &&
      //         ea[ind] !== null &&
      //         dat[ind] !== null
      //       ) {
      //         if (dat[ind]["_id"] === ea[ind]["_id"]) {
      //           dat[ind] = ea[ind];
      //         } else if (
      //           dat[ind["allowanceId"]] === ea[ind]["allowanceId"] &&
      //           ind === ind &&
      //           ea[ind].length === 0
      //         ) {
      //           dat[ind] = ea[ind];
      //         }
      //       } else if (
      //         !dat[ind] &&
      //         (ea[ind].amount > 0 || ea[ind].percentage > 0)
      //       ) {
      //         dat[ind] = ea[ind];
      //         // console.log(ea[ind]);
      //       }
      //     });
      //   });
      // });
      const update = data.map((emp, index) => {
        return editedAllowanceDetails.value.map((ea, i) => {
          if (index === i) {
            emp.allowanceDetails = ea;
          }
        });
      });
      return update;
    }
    function updateHiddenFields() {
      const names = allowanceNames.value.map((name) => {
        hiddenFields.value.push(`${name}allowanceId`, `${name}_id`);
        return hiddenFields.value;
      });
    }
    const fieldsToHide = updateHiddenFields();
    const propD = ref(props.data);

    watch(propD, (val, newVal) => {
      // transformedData.value = transform(val);
      allowance.value = extractAllowance(newVal);
      datum.value = finalVal();
      payrollData.value = cross();

      updateVisibleData();
    });

    //Pagination Functions

    return {
      startItem,
      endItem,
      showModalForMultipleEdit,
      formatAmount,
      calculatePercentage,
      editInProgress,
      payrollData,
      visibleData,
      updateVisibleData,
      allowanceNames,
      // header,
      triggerUpdate,
      editedAllowanceDetails,
      updateAllowanceArray,
      hiddenFields,
      pageSize,
      currentPage,
      updatePage,
      updateSize,
      allowance,
      datum,
      payrollData,
      extractAllowance,
      finalVal,
      cross,
    };
  },

  components: { EditGroup },
  emits: ["selected", "updated", "multiple", "setModalToFalse"],
  props: {
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    hideAllowances: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: [],
    },
    namesForAllowances: {
      type: Array,
      default: [],
    },
    amountValues: {
      type: Array,
      default: [],
    },
    // header: {
    //   type: Array,
    //   default: [],
    // },
    mapData: {
      type: Array,
      default: [],
    },
    frozenFields: {
      type: Array,
      default: [],
    },
    highlight: {
      type: Array,
      default: [],
    },
    useSelect: {
      type: Array,
      default: [],
    },
    mandatory: {
      type: Array,
      default: [],
    },
    routable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedEmployees: null,
      editingRows: [],
      columns: null,
      holdData: [],
      active: "",
      nonInputs: ["deductions", "firstName", "lastName", "netPay"],
      checkAll: false,
    };
  },

  computed: {
    multiple() {
      return this.allowMultiple;
    },
    bodyCheckStyle() {
      if (this.hideAllowances) {
        return `width:3em; border:1px solid #E7E8E7; color:#515251; background: #F9FAFC`;
      }
      return `width:3em; border:1px solid #E7E8E7; color:#515251; background: #F9FAFC`;
    },
    style() {
      return `overflow: hidden; padding-top: 0.75rem; padding-bottom: 0.75rem; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center; text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: #e9efed; margin-bottom: 10px; color: #515251; border: 1px solid #e9efed; font-size: 12px; box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);`;
    },
    activeHeader() {
      return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #e9efed; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 2px; border-top-width: 0; background: #e9efed;margin-bottom: 10px;color: #ffffff; font-size: 14px;";
    },
    headStyle() {
      if (this.hideAllowances === false) {
        return "padding-top: 0.75rem; padding-bottom: 0.75rem; background: #e9efed;margin-bottom: 10px; border-top-left-radius: 1rem; border-bottom-left-radius: 0rem; border: 1px solid #e9efed; font-size: 12px; text-align: center; width:4em";
      }
      return "width: 4em; border: 1px solid #B5BDE1; background: #e9efed; border-top-left-radius: 1rem;border-bottom-left-radius: 0rem; border: 1px solid #e9efed; font-size: 12px; text-align: left;margin-bottom: 10px;";
    },
    lastHeaderStyle() {
      return "padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #e9efed; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 1px; border-top-width: 1px;margin-bottom: 10px;border-top-right-radius: 1rem;border-bottom-right-radius: 0rem;color: #515251; font-size: 14px;";
    },
    label() {
      return (str) =>
       str&& str
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1")
          .toUpperCase();
    },
    freeze() {
      return (field) => {
        if (this.frozenFields.includes(field.field)) {
          return true;
        }
        return false;
      };
    },
    highlightField() {
      return (field) => {
        if (this.highlight.includes(field)) {
          return "background: rgba(240, 247, 235, 1); box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.2); z-index:0";
        }
        return "background: #FCFCFC; z-index: 0";
      };
    },
    highlightHeader() {
      return (field) => {
        if (this.highlight.includes(field)) {
          return "background: rgba(240, 247, 235, 1); box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.2); z-index:1";
        }
        return "background: #FCFCFC; z-index: 0";
      };
    },
    // mandatoryField() {
    //   return (field) => {
    //     if (this.mandatory.includes(field)) {
    //       return "border: 1px solid #FFF0EC";
    //     }
    //   };
    // },
    header() {
      // let headArr = [];
      // const head = Object.keys(this.payrollData.length && this.payrollData[0]);
      // for (let i of head) {
      //   headArr.push({
      //     field: i,
      //     header: i,
      //   });
      // }

      // return headArr;
      if (this.payrollData.length > 0) {
    //get all object keys and remove duplicates
    let allKeys = [];
    this.payrollData.forEach((item) => {
      for (const i in item) {
        allKeys.push(i);
      }
    });

    let headArr = [];
    const uniqueKeys = [...new Set(allKeys)];
    for (let i of uniqueKeys) {
      headArr.push({
        field: i,
        header: i,
      });
    }
    // console.log(headArr, 'harr')
    return headArr;
  }
    },
    propsData() {
      return this.data;
    },
    employeesSelected() {
      return this.selectedEmployees;
    },
  },

  watch: {
    propsData: function (val, newVal) {
      this.allowance = this.extractAllowance(val);
      this.datum = this.finalVal();
      this.payrollData = this.cross();

      this.updateVisibleData();
    },

    multiple() {
      this.showModalForMultipleEdit = this.allowMultiple;
    },
    employeesSelected() {
      this.$emit("selected", this.selectedEmployees);
    },
  },

  methods: {
    selectAllEmps(e) {
      this.checkAll = !this.checkAll
      if(this.checkAll===true){
        this.selectedEmployees = this.payrollData;
        return
      }
      this.selectedEmployees = []
    },
    sendToPage(event) {
      this.$emit("multiple", event);
    },
    treat() {
      this.showModalForMultipleEdit = false;
      this.$emit("setModalToFalse", false);
    },
    getDeductionLabel(arr) {
      if (arr&&arr.length) {
        const data = arr.map((item) => {
          if (item === "PAYE") return "PAYE";
          if (item === "Pension") return "Pension";
          if (item === "NHF") return "NHF";
          if (item === "NSITF") return "NSITF";
          if (item === "ITF") return "ITF";
          if (item === "WHT") return "WHT";
          if (item === "Group_Life_Insurance") return "Life Insurance";
          if (item === "Employee_Life_Assurance") return "Life Assurance";
          if (item === "HMO") return "HMO";
          if (item === "Business_Expense") return "Business Expense";
          if (item === "Gratuity") return "Gratuity";
        });
        return data;
      }
      return ["N/A"];
    },
    returnOptions(fieldType) {
      if (fieldType === "employmentType") {
        return this.mapData[0];
      } else if (fieldType === "branch") {
        return this.mapData[1];
      }
    },
    updateMultipleCells(event, obj, objkey) {
      // console.log("1", event, "2", obj, "3", objkey);
      this.active = objkey;
      for (let i = 0; i < this.holdData.length; i++) {
        if (event.target) {
          this.holdData[i][objkey] = event.target.value;
          obj[objkey] = event.target.value;
        } else {
          this.holdData[i][objkey] = event.value;
          obj[objkey] = event.value;
        }
      }
    },
    initials(name) {
      const arr = name.split(" ");
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return str;
    },
    getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    },

    getAvatar(employee) {
      let name = `${employee&&employee.firstName} ${employee&&employee.lastName}`;
      let initialLetters = this.initials(name);

      let color;
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(employee && employee.firstName&& employee.firstName.charAt(0));
      const initialTwo = String(employee && employee.lastName&& employee.lastName.charAt(0));
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = this.getColor(num);
      // Get color

      return {
        color,
        initialLetters,
      };
    },
    // getAvatar(employee) {
    //   let name = `${employee.firstName} ${employee.lastName}`;
    //   let initialLetters = this.initials(name);
    //   let color;
    //   // Get color
    //   const colors = [
    //     "#D32F2F",
    //     "#189F00",
    //     "#0A244F",
    //     "#E4572E",
    //     "#01AA8C",
    //     "#184EA8",
    //     "#F39C36",
    //     "#4f46e5",
    //   ];

    //   const randomNumber = Math.floor(Math.random() * 5);

    //   color = colors[randomNumber + 1];

    //   return {
    //     color,
    //     initialLetters,
    //   };
    // },
    emitSelectedFromMobile(event) {
      this.holdData = event;
      this.$emit("selected", this.holdData);
    },
    onRowSelect(event) {
      // console.log(this.holdData, this.selectedEmployees);
      this.holdData.push(event.data);
      this.$emit("selected", this.holdData);
    },
    onRowUnselect(event) {
      this.holdData = this.holdData.filter((emp) => {
        return emp.employeeId !== event.data.employeeId;
      });
      this.$emit("selected", this.holdData);
    },
    onRowClick(event) {
      if (this.routable) {
        const empId = event.data.employeeId;

        this.$router.push({
          name: this.$route.path.includes("edit")
            ? "EditSingleSalary"
            : "ViewSingleSalary",
          params: { id: empId },
        });
      } else {
        // this.holdData = [];
        this.holdData.push(event.data);
      }
    },
    bankError(data, field) {
      if (data.bankError) {


        const bankError = data.bankError;
        if (
          field === "bankName" ||
          field === "bankCode" ||
          field === "accountNumber"
        ) {
          return [
            {
              "border border-error-table w-full h-full text-error-table p-2":
                bankError.length > 0,
            },
          ];
        }
      }
    },
    error(data, field) {
      if (data.reason) {


        const reason = data.reason;

        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              reason.includes(field),
          },
        ];
      }
    },
    madatoryClass(data, field) {

      return [
        {
          "border border-secondary w-full h-full text-secondary":
            data[field].length === 0,
        },
      ];
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    onCellEditComplete(event) {
      this.editInProgress = false;
      let { data, newValue, field } = event;


      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue.length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
      this.editedAllowanceDetails = this.triggerUpdate(this.holdData); //switchimg to holdData (array that holds selected/edited values) from visibleData
      const updateAll = this.updateAllowanceArray(this.holdData);

      const unique = this.getUniqueListBy(this.holdData, "staffLevelId");


      this.$emit("updated", unique);
    },
    updateForMobile(arr) {
      this.editedAllowanceDetails = this.triggerUpdate(arr);
      const updateAll = this.updateAllowanceArray(arr);

      const unique = this.getUniqueListBy(arr, "staffLevelId");

      this.$emit("updated", unique);
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },
  },
  created() {
    if (this.hideAllowances === true) {
      const staticAllowances = ["basic", "transport", "housing"];
      for (let name of this.allowanceNames) {
        if (!staticAllowances.includes(name)) {
          this.hiddenFields.push(name);
        }
      }
    }

  },
};
</script>

<style scoped>
.shadow {
  background: rgba(240, 247, 235, 1);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.2);
}

#custom .p-checkbox .p-checkbox-box {
  background: #115811 !important;
}
</style>
